<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="callout" :class="{'callout__warning': type === 'warning', 'callout__info': type === 'info', 'callout__error': type === 'danger', 'callout__success': type === 'success'}"  v-if="_visible">
    <div class="flex">
      <div class="callout--icon" v-if="!hideIcon">
        <Component :is="icon" class="h-5 w-5" aria-hidden="true"/>
      </div>
      <div :class="{'ml-3': !hideIcon}">
        <h3 class="callout--title">
          {{ title }}
        </h3>
        <div class="callout-content" v-if="$slots.default">
          <p>
            <slot></slot>
          </p>
        </div>
        <div class="mt-4">
          <div class="-mx-2 -my-1.5 flex">
<!--          TODO handle link action-->
            <button v-for="(action, k) in actions" :key="k"
              type="button"
              class="callout--button"
            >
              {{ action.title }}
            </button>
          </div>
        </div>
      </div>
      <div class="ml-auto pl-3" v-if="dismissable">
        <div class="-mx-1.5 -my-1.5">
          <button @click="dismiss" type="button"
            class="callout--dismiss"
          >
            <span class="sr-only">Dismiss</span>
            <XMarkIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckCircleIcon from '@heroicons/vue/24/solid/CheckCircleIcon';
import XCircleIcon from '@heroicons/vue/24/solid/XCircleIcon';
import ExclamationCircleIcon from '@heroicons/vue/24/solid/ExclamationCircleIcon';
import InformationCircleIcon from '@heroicons/vue/24/solid/InformationCircleIcon';
import XMarkIcon from '@heroicons/vue/24/solid/XMarkIcon';

export default {
  props: {
    color: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'success' // info, success, warning, danger
    },
    visible: {type: Boolean, default: true},
    hideIcon: {type: Boolean, default: false},
    dismissable: {type: Boolean, default: false},
    title: {type: String, default: ''},
    actions: {type: Array, default: () => []},
  },
  data() {
    return {
      dismissed: false
    }
  },
  methods: {
    dismiss() {
      // TODO handle cookie set in order to not re-show to the user
      this.dismissed = true;
    }
  },
  computed: {
    _visible() {
      if (this.dismissed) return false;
      return this.visible
    },
    icon() {
      if (this.type === 'danger') {
        return XCircleIcon;
      }
      if (this.type === 'warning') {
        return ExclamationCircleIcon;
      }
      if (this.type === 'success') {
        return CheckCircleIcon;
      }
      return InformationCircleIcon;
    }
  },
  components: {
    CheckCircleIcon,
    XCircleIcon,
    ExclamationCircleIcon,
    InformationCircleIcon,
    XMarkIcon
  },
}
</script>
